export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/',
    name: 'inicio',
    component: () => import('../components/app/inicio/Inicio.vue'),
    meta: {
      logged: false,
      title: 'Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: {
      logged: false,
      title: 'Essa página não existe na JokenPlay...',
      layout: 'default-layout'
    }
  },
  {
    path: '/sorteios',
    name: 'sorteios',
    component: () => import('../components/app/sorteios/Sorteios.vue'),
    meta: {
      logged: true,
      title: 'Sorteios | Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/ganhadores',
    name: 'ganhadores',
    component: () => import('../components/app/ganhadores/Ganhadores.vue'),
    meta: {
      logged: true,
      title: 'Ganhadores | Oficina das Rifas',
      layout: 'navbar-layout'
    }
  }
]
